.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* my style */
.advantage-dec {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: rgba(255, 255, 255, 1);
  .anticon {
    margin-right: 12px;
    color: #5bf507;
  }
}
.recharge-title {
  margin-bottom: 1.5em;
}
.recharge-content-bt1 {
  margin-bottom: -5em;
}
.recharge-content-bt2 {
  margin-bottom: 2em;
}
.recharge-box {
  .recharge-box-outer.can-click {
    width: 100%;
    height: 170px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 20px;
    border: 1px solid #dee3eb;
    border-radius: 15px;
    img {
      width: 100%;
      transition: 0.5s all;
      &:hover {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transition: 0.8s all;
        -webkit-transition: 0.8s all;
        -moz-transition: 0.8s all;
        -ms-transition: 0.8s all;
        -o-transition: 0.8s all;
      }
    }
  }
}
.footer-row {
  display: flex !important;
  align-items: center;
  margin-bottom: 20px;
}
.footer .footer-sub-menu .footer-link {
  color: rgba(255, 255, 255, 0.5) !important;
  transition: all 0.3s;
  margin-bottom: 7px !important;
  &:hover {
    color: #fff !important;
    margin-left: 5px;
  }
}
.contact-mail {
  display: flex;
  align-items: center;
  margin-left: 15px;
  color: #fff;
  .anticon-mail {
    margin-right: 5px;
  }
}
.nav-sticky {
  .contact-mail {
    color: #999;
  }
}
.bg-nav {
  background: #2753ff !important;
}
.privacyPolicy-box {
  width: 1200px;
  margin: 0 auto;
  padding: 120px 0 100px 0;
  .text-indent-28 {
    text-indent: 28px;
  }
  .text-indent-56 {
    text-indent: 56px;
  }
}
.charge-page {
  width: 1200px;
  margin: 0 auto;
  padding: 100px 0 80px 0;
  .cut-line {
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
    margin: 32px 0;
  }
  .charge-title {
    line-height: 2em;
    font-size: 22px;
  }
  .charge-tips {
    color: #0000ff;
    line-height: 2em;
  }
  .charge-item {
    display: flex;
    align-items: center;
    &.text-item {
      line-height: 3em;
    }
    .label {
      font-size: 14px;
      color: #333;
      width: 52px;
    }
    .value {
      font-size: 16px;
      font-weight: 600;
    }
    .bg-value {
      width: 100px;
      height: 32px;
      background-color: rgba(129, 211, 248, 0.13);
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
    }
  }
  .price-list {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    list-style: none;
    padding: 0;
    li {
      cursor: pointer;
      width: 100px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #666;
      margin-right: 12px;
      &.on {
        background-color: rgba(129, 211, 248, 0.13);
        border: 0;
      }
      &:hover {
        background-color: rgba(129, 211, 248, 0.13);
        border: 0;
      }
    }
  }
  .buy-btn {
    width: 80%;
    margin-top: 32px;
  }
  .charge-description {
    p {
      margin-bottom: 0;
    }
    section {
      margin-bottom: 20px;
    }
  }
  .pay-way-img {
    height: 40px;
    background-size: cover;
    margin-top: 10px;
  }
}
.payment-page {
  width: 1200px;
  margin: 0 auto;
  padding: 100px 0 80px 0;
  .payment-page-box {
    margin: 0 auto;
    justify-content: center;
  }
  .credit-card {
    padding: 16px 16px 0;
    margin-bottom: 10px;
    background-color: #f2f2f2;
    .credit-card-title {
      color: #7f7f7f;
      font-size: 12px;
    }
  }
  .form-control {
    background-color: #fff;
  }
  .visa-input {
    position: relative;
  }
  .visa-img {
    position: absolute;
    right: 1px;
    top: 1px;
    height: 38px;
    border-radius: 0.375rem;
  }
  .cvc-img {
    position: absolute;
    right: 1px;
    top: 2px;
    height: 36px;
    border-radius: 0 0.375rem 0.375rem 0;
    -webkit-border-radius: 0 0.375rem 0.375rem 0;
    -moz-border-radius: 0 0.375rem 0.375rem 0;
    -ms-border-radius: 0 0.375rem 0.375rem 0;
    -o-border-radius: 0 0.375rem 0.375rem 0;
  }
  .submit-btn-group {
    display: flex;
    justify-content: center;
    button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
.payment-icon {
  font-size: 38px;
  color: #ffc107;
}
.result-return-btn {
  margin-bottom: 30px;
  margin-top: 100px;
}
.payment-result {
  height: calc(100vh - 139px);
  display: flex;
  align-items: center;
}
.space {
  margin-top: 10px;
}
